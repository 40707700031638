import { React, useState, useEffect } from 'react';
import convert from 'convert';

const TableConverter = ({ units }) => {


  return (
    <>

      <h4>Conversion table for 1 unit</h4>
      <table className="table table-dark">
      <thead className="thead-dark">
        <tr>
          <td></td>
          {units.map((unit) =>

            <th  scope="col" className='text-right' key={"th" + unit.simbol}>{unit.label}</th>

          )}
        </tr>
        </thead>
        <tbody>
        {units.map((unit) =>
          <tr key={"tr" + unit.simbol}>
            <th scope="col" key={"td" + unit.simbol}>{unit.label}</th>
            {units.map((unitCell) => <td className='text-right' scope="col">
              {
                parseFloat(
                convert(Number(1), unitCell.convUnit).to(unit.convUnit).toFixed(6)
                )
                }</td>)}
          </tr>
        )}
</tbody>
      </table>
    </>
  );
}


export default TableConverter;
