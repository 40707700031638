import React, { useState, useEffect } from 'react';


const CopyValue = (props) => {
  const [valueToCopy, setValueToCopy] = useState('99');
  const [alertElement, setAlertElement] = useState('');
  //let alertElement ='';

  useEffect(() => {
    setValueToCopy(props.valueToCopy);
  }, [props,alertElement]);

  //useEffect(() => { setValue(valueToCopy) }, [valueToCopy]);
  //useEffect(() => { );

  function copyValue1() {

    // Copy the text inside the text field
    navigator.clipboard.writeText(valueToCopy);

    // Alert the copied text
/*
    setAlertElement(
      <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Holy guacamole!</strong> You should check in on some of those fields below.
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>);
*/
  }

  return (
    <>
    {alertElement}
      <button type="button" className="btn btn-primary bi bi-clipboard-plus"
        value="Copy" onClick={copyValue1}></button>

      
    </>
  );
}

export default CopyValue;
