export const unitsNumbers = [
    { label: 'Binary', convUnit: "Binary", simbol: 'Binary', base: 2,key: 1 },
    { label: 'Octal', convUnit: "Octal", simbol: 'Octal', base: 8,key: 2 },
    { label: 'Decimal', convUnit: "Decimal", simbol: 'Decimal', base: 10,key: 3 },
    { label: 'Hexadecimal', convUnit: "Hexadecimal", simbol: 'Hexadecimal', base: 16 ,key: 4}
  ];

  export const commonConversionNumbers =[
    {link: '?1-Decimal-to-Binary', label:'1 Decima to Binary'},
    {link: '?1-Decimal-to-Octal', label:'1 Decimal to Octal'},
    {link: '?A-Hexadecimal-to-Decimal', label:'A Hexadecimal to Decimal'}
  ]

  export const unitsLength = [
    { label: 'Millimeter', convUnit: "millimeter", simbol: 'mm', key: 1 },
    { label: 'Centimeter', convUnit: "centimeter", simbol: 'cm', key: 2 },
    { label: 'Meter', convUnit: "meter", simbol: 'm', key: 3 },
    { label: 'Kilometer', convUnit: "kilometer", simbol: 'km', key: 4},
    { label: 'Inch', convUnit: "inch", simbol: 'in', key: 5 },
    { label: 'Foot', convUnit: "foot", simbol: 'ft', key: 6 },
    { label: 'Yard', convUnit: "yard", simbol: 'yd', key: 7 },
    { label: 'Mile', convUnit: "mile", simbol: 'mi', key: 8},
  ];
  export const commonConversionLength =[
    {link: '?1-meter-to-mile', label:'1 meter to mile'},
    {link: '?1-meter-to-inch', label:'1 meter to inch'},
    {link: '?1-meter-to-foot', label:'1 meter to foot'},
    {link: '?1-meter-to-yard', label:'1 meter to yard'}
  ]
  
  
  export const unitsTemperature = [
    { label: 'Celsius', convUnit: "celsius", simbol: 'C', key: 1 },
    { label: 'Fahrenheit', convUnit: "fahrenheit", simbol: 'F', key: 2},
    { label: 'Kelvin', convUnit: "kelvin", simbol: 'K', key: 3 },
    { label: 'Rankine', convUnit: "rankine", simbol: 'R', key: 4 }
  ];
  
  export const commonConversionTemperature =[
    {link: '?1-celsius-to-kelvin', label:'1 celsius to kelvin'},
    {link: '?1-celsius-to-fahrenheit', label:'1 celsius to fahrenheit'},
    {link: '?1-celsius-to-rankine', label:'1 celsius to rankine'}
  ]
  
  export const unitsWeigth = [
    {  label:'kilogram', convUnit: "kg", simbol: 'kg', key: 1 },
    { label: 'gram', convUnit: "gram", simbol: 'g', key: 1 },
    { label: 'milligram', convUnit: "milligram", simbol: 'mg', key: 2},
    //{ label: 'ton', convUnit: "ton", simbol: 't', key: 3 },
    { label: 'pound', convUnit: "pound", simbol: 'lbs', key:  4},
    { label: 'ounce', convUnit: "ounce", simbol: 'oz', key: 5 },
    //{ label: 'carat', convUnit: "carat", simbol: 'car', key: 6 },
    //{ label: 'Atomic mass unit', convUnit: "Atomic mass unit", simbol: 'u', key: 7 }
  ];
  
  export const unitsTime = [
  {  label: "second",convUnit: "s", simbol: "s", key:  1},
  {  label: "petasecond",convUnit: "Ps", simbol: "Ps", key:  2},
  {  label: "terasecond",convUnit: "Ts", simbol: "Ts", key:  3},
  {  label: "gigasecond",convUnit: "Gs", simbol: "Gs", key:  4},
  {  label: "megasecond",convUnit: "Ms", simbol:"Ms", key:  5},
  {  label: "kilosecond",convUnit: "ks", simbol: "ks", key:  6},
  {  label: "hectosecond",convUnit: "hs", simbol:"hs" , key:  7},
  {  label: "decasecond",convUnit: "das", simbol:"das" , key: 8 },
  {  label: "decisecond",convUnit: "ds", simbol:"ds" , key:  9},
  {  label: "centisecond",convUnit: "cs", simbol: "cs", key: 10 },
  {  label: "millisecond",convUnit: "ms", simbol:"ms" , key: 11 },
  {  label: "microsecond",convUnit: "μs", simbol: "μs", key: 12 },
  {  label: "nanosecond",convUnit: "ns", simbol:"ns" , key: 13 },
  {  label: "picosecond",convUnit: "ps", simbol: "ps", key:  14},
  {  label: "femtosecond",convUnit: "fs", simbol:"fs" , key: 15 },
  {  label: "minute",convUnit: "min", simbol: "min", key:  16},
  {  label: "hour",convUnit: "h", simbol: "h", key:  17},
  {  label: "milliday",convUnit: "md", simbol: "md", key:  18},
  {  label: "day",convUnit: "d", simbol: "d", key:  19},
  {  label: "week",convUnit: "wk", simbol: "wk", key: 20 },
  {  label: "fortnight",convUnit: "fn", simbol: "fn", key:  21},
  {  label: "month",convUnit: "mo", simbol: "mo", key:22 },
  {  label: "year",convUnit: "y", simbol: "y", key:  23},
  {  label: "decade",convUnit: "dec", simbol: "dec", key: 24},
  {  label: "century", convUnit: "centuries", simbol: "centuries", key:  25},
  {  label: "millennium",convUnit: "millennia", simbol: "millennia", key:  26},
  {  label: "moment",convUnit: "moments", simbol: "moments", key: 27 },
  {  label: "shake",convUnit: "shakes", simbol: "shakes", key: 28 },
  {  label: "time unit",convUnit: "TU", simbol:"TU" , key:  29},
  {  label: "svedberg",convUnit: "S", simbol: "S", key: 30 }
  ];
  
  export const unitsPressure = [
    {label: "pascal",         convUnit: "Pa",     simbol: "Pa"},
    {label: "petapascal",     convUnit: "PPa",    simbol: "PPa"},
    {label: "terapascal",     convUnit: "TPa",    simbol: "TPa"},
    {label: "gigapascal",     convUnit: "GPa",    simbol: "GPa"},
    {label: "megapascal",     convUnit: "MPa",    simbol: "MPa"},
    {label: "kilopascal",     convUnit: "kPa", simbol: "kPa"},
    {label: "hectopascal",    convUnit: "hPa", simbol: "hPa"},
    {label: "decapascal",     convUnit: "daPa", simbol: "daPa"},
    {label: "decipascal",     convUnit: "dPa", simbol: "dPa"},
    {label: "centipascal",    convUnit: "cPa", simbol: "cPa"},
    {label: "millipascal",    convUnit: "mPa", simbol: "mPa"},
    {label: "micropascal",    convUnit: "μPa", simbol: "μPa"},
    {label: "nanopascal",     convUnit: "nPa", simbol: "nPa"},
    {label: "picopascal",     convUnit: "pPa", simbol: "pPa"},
    {label: "femtopascal",    convUnit: "fPa", simbol: "fPa"},
    {label: "bar",            convUnit: "bars", simbol: "bars"},
    {label: "petabar",        convUnit: "Pbar", simbol: "Pbar"},
    {label: "terabar",        convUnit: "Tbar", simbol: "Tbar"},
    {label: "gigabar",        convUnit: "Gbar", simbol: "Gbar"},
    {label: "megabar",        convUnit: "Mbar", simbol: "Mbar"},
    {label: "kilobar",        convUnit: "kbar", simbol: "kbar"},
    {label: "hectobar",       convUnit: "hbar", simbol: "hbar"},
    {label: "decabar",        convUnit: "dabar", simbol: "dabar"},
    {label: "decibar",        convUnit: "dbar", simbol: "dbar"},
    {label: "centibar",       convUnit: "cbar", simbol: "cbar"},
    {label: "millibar",       convUnit: "mbar", simbol: "mbar"},
    {label: "microbar",       convUnit: "μbar", simbol: "μbar"},
    {label: "nanobar",        convUnit: "nbar", simbol: "nbar"},
    {label: "picobar",        convUnit: "pbar", simbol: "pbar"},
    {label: "femtobar",       convUnit: "fbar", simbol: "fbar"},
    {label: "torr",           convUnit: "Torr", simbol: "Torr"},
    {label: "millitorr",      convUnit: "mTorr", simbol: "mTorr"},
    {label: "atmosphere",  convUnit: "atm",  simbol: "atm"}
  ];
  

  export const unitsGpa = [
    {grade: "A+",   min:94,max:100	,gpa:4.33},
    {grade: "A",   min:94,max:100	,gpa:4.0},
    {grade: "A-",  min:	90,max:93	,gpa:3.7},
    {grade: "B+",  min:	87,max:89	,gpa:3.3},
    {grade: "B",   min:	84,max:86	,gpa:3.0},
    {grade: "B-",  min:	80,max:83	,gpa:2.7},
    {grade: "C+",  min:	77,max:79	,gpa:2.3},
    {grade: "C",   min:	74,max:76	,gpa:2.0},
    {grade: "C-",  min:	70,max:73	,gpa:1.7},
    {grade: "D+",  min:	67,max:69	,gpa:1.3},
    {grade: "D",  min:	64,max:66	,gpa:1.0},
    {grade: "D-",  min:	60,max:63	,gpa:0.7},
    {grade: "F",   min:	0,max:65	,gpa:0}
  ];
 

  
  