import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes,Navigate } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from './pages/Home';
import {
unitsNumbers
,unitsLength
,unitsTemperature
,unitsTime
,unitsPressure
,unitsWeigth
,unitsGpa
,commonConversionLength
,commonConversionTemperature
,commonConversionNumbers

} from './converters/units';

import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import UniConverter from './converters/UniConverter';
import NumberSystemConverter from './converters/NumberSystemConverter';
import GPACalculator from './converters/GPACalculator'
const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<><Layout /><Home /></>}>

      </Route>
        <Route path="/" element={<Layout />}>
      
          <Route path="lengthConverter" element={
          <UniConverter units={unitsLength} _fromUnit="m" _toUnit="m" _value="1" _title="Length Converter" _commonConversion={commonConversionLength} _icon="bi bi-rulers" />
          } />
          <Route path="temperatureConverter" element={<UniConverter units={unitsTemperature} _fromUnit="C" _toUnit="K" _value="22" _title="Temperature Converter" _commonConversion={commonConversionTemperature}  _icon="bi bi-thermometer-half"/>} />
          <Route path="weigthConverter" element={<UniConverter units={unitsWeigth} _fromUnit="g" _toUnit="g" _value="1" _title="Weigth Converter" _commonConversion={[]}  _icon=""/>} />
          <Route path="timeConverter" element={<UniConverter units={unitsTime} _fromUnit="s" _toUnit="s" _value="1" _title="Time Converter" _commonConversion={[]}  _icon="bi bi-clock"/>} />
          <Route path="pressureConverter" element={<UniConverter units={unitsPressure} _fromUnit="Pa" _toUnit="bars" _value="1" _title="Pressure Converter" _commonConversion={[]}  _icon=""/>} />
          <Route path="numberConverter" element={<NumberSystemConverter units={unitsNumbers} _fromUnit="Decimal" _toUnit="Hexadecimal" _value="1" _title="Number system Converter" _commonConversion={commonConversionNumbers}  _icon=""/>} />
          <Route path="GPACalculator" element={<GPACalculator units={unitsGpa} _title="GPA calculator"  _icon=""/>} />
         
          
          <Route path='*' element={<Navigate to='/' />} />
        </Route>
      </Routes>
    </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
