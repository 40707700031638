import { useEffect, useState } from 'react';
import { BaseNumeralSystem } from 'base-numeral-system';

import { useLocation } from 'react-router-dom';
import CopyValue from '../utils/CopyValue';
import * as urlUtils from '../utils/urlUtils';
import TableConverter from './TableConverter';

const NumberSystemConverter = ({ units, _fromUnit, _toUnit, _value, _title, _commonConversion, _icon }) => {

  const [fromUnit, setFromUnit] = useState(_fromUnit);
  const [toUnit, setToUnit] = useState(_toUnit);
  const [value, setValue] = useState(_value);
  const [valueConverted, setValueConverted] = useState();

  let urlComps = urlUtils.getUrlComponents(useLocation(), units);

  console.log(units.filter(unit => unit.simbol == fromUnit)[0])

  useEffect(() => {
    if (urlComps.urlValue != null && urlComps.urlValue != '') {

      setValue(urlComps.urlValue)
      setFromUnit(urlComps.urlFromUnit)
      setToUnit(urlComps.urlToUnit)
    }
  }, [])


  useEffect(() => {
    convertUnits(value);
  }, [fromUnit, toUnit, value, valueConverted]);


  const handleClick = e => {
    e.preventDefault();
    setFromUnit(e.target.value);
  }

  const handleClickTo = e => {
    e.preventDefault();
    setToUnit(e.target.value);
  }

  const convertUnits = e => {

    let baseFrom;
    let baseTo;
    units.map(
      (unit) => {
        if (fromUnit === unit.simbol) {
          baseFrom = unit.base
          return unit.base
        }

      });

    units.map(
      (unit) => {
        if (toUnit === unit.simbol) {
          baseTo = unit.base
          return unit.base
        }

      });


    try { 
      let res = new BaseNumeralSystem().from(baseFrom).to(baseTo).convert(value);
      console.log('- ' + baseFrom + ' ' + baseTo + ' ' + res)
  
      setValueConverted(
        res
        //0
        //cnv(value,2,10)
        //BaseNumeralSystem.from(baseFrom).to(baseTo).convert(value)
      )

    }
    catch { 
      setValueConverted('Error')

    }


    
  }

  const onValueChange = e => {
    setValue(e.target.value)
  }
  const swap = e => {
    let vFrom = fromUnit
    setFromUnit(toUnit)
    setToUnit(vFrom)
  }

  return (
    <>

      <div className='container'>
        <h2>{_title} <i className={_icon}></i> </h2>
        <input
          onChange={onValueChange} value={value}></input>


        <div className="btn-group mr-2" role="group" aria-label="First group">
          {units.map((unit) =>
            <button key={unit.simbol} type="button" className={fromUnit === unit.simbol ? "btn btn-primary" : "btn btn-secondary"} value={unit.simbol} onClick={handleClick}>{unit.label}</button>
          )}
        </div>
        <br />
        <input id="distance" disabled value={valueConverted}></input>
        <div className="btn-group mr-2" role="group" aria-label="First group">
          {
            units.map((unit) =>
              <button key={unit.simbol} type="button" className={toUnit === unit.simbol ? "btn btn-primary" : "btn btn-secondary"} value={unit.simbol} onClick={handleClickTo}>{unit.label}</button>
            )
          }
        </div>

        <br />
        <h5>{value} {fromUnit} is {valueConverted} {toUnit}</h5>
        <CopyValue key="UK1" valueToCopy={valueConverted}></CopyValue>
        <button type="button" className="btn btn-primary bi bi-shuffle" value="Swap" onClick={swap}></button>
        <h4>Common conversions</h4>
        <div className='container-fluid'>

          {

            _commonConversion.map((commonConv) =>
              <><a href={commonConv.link} className="link-primary">{commonConv.label}</a><br /></>
            )
          }

        </div>

      </div>

    </>
  );
}

export default NumberSystemConverter;