import {unitsLength
    ,unitsTemperature
    ,unitsTime
    ,unitsPressure
    ,unitsWeigth
    ,commonConversionLength
    ,commonConversionTemperature
    } from '../converters/units';

const Home = () => {
    return (
    <div className='container'>
    <h1>Unit Conversion</h1>
    <p>Welcome to our web site, where you can easily convert units of measurement. We support a wide range of units including length, weight, temperature, and more. Our user-friendly interface makes it easy to quickly convert units, so you can get back to your work or project.</p>
<p>Some of the units we support include:</p>
<ul>
<li>Length (inches, feet, meters, etc.)</li>
<li>Weight (ounces, pounds, kilograms, etc.)</li>
<li>Temperature (Fahrenheit, Celsius, Kelvin, etc.)</li>
</ul>
<p>Try our unit conversion tool now and see how easy it is to convert units!</p>
    <p>
        On this site you can find a various conversion tolls, like:<br />
      
        <a href="/lengthConverter" className="btn btn-primary">Length</a>
        <a href="/temperatureConverter" className="btn btn-primary">Temperature</a>
        <a href="/weigthConverter" className="btn btn-primary">Weigth</a>  
        <a href="/timeConverter" className="btn btn-primary">Time</a>
        <a href="/pressureConverter" className="btn btn-primary">Pressure</a>
        
        
    </p>
    <h2>Common conversions</h2>
    <h3>Length</h3>
    <div className='container-fluid'>
          {commonConversionLength.map((commonConv) =>
            <><a href={"/lengthConverter" + commonConv.link} className="link-primary">{commonConv.label}</a><br /></>
            )}
    </div>
    <h3>Temperature</h3>
    <div className='container-fluid'>
          {commonConversionTemperature.map((commonConv) =>
            <><a href={"/temperatureConverter" + commonConv.link} className="link-primary">{commonConv.label}</a><br /></>
            )}
    </div>
    </div>
    );
  };
  
  export default Home;