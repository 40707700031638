import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  return (
    <>
        <div className='container'>
          <h1>
          <a className="nav-link active" aria-current="page" href="/">Converter tools</a>
            </h1>
          <ul className="nav nav-tabs">

            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="/lengthConverter">Length</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/temperatureConverter">Temperature</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/weigthConverter">Weigth</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/timeConverter">Time</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/pressureConverter">Pressure</a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="/numberConverter">Number Converter</a>
            </li>
            {/*
            <li className="nav-item" style={{visibility: false}}>
              <a className="nav-link" href="/GPACalculator">GPA Calculator</a>
            </li>
            */
            }

            
            
          </ul>
          <Outlet />
        </div>
      </>
  )
};


export default Layout;