import { useEffect, useState,useRef } from 'react';
import { BaseNumeralSystem } from 'base-numeral-system';
import { AgGridReact } from 'ag-grid-react';

import { useLocation } from 'react-router-dom';
import CopyValue from '../utils/CopyValue';
import * as urlUtils from '../utils/urlUtils';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

import {
  unitsGpa
} from '../converters/units';

const GPACalculator = ({ units, _fromUnit, _toUnit, _value, _title, _commonConversion, _icon }) => {

  const [rowData, setRowData] = useState([
    { course: "English", grade: "A", credit: 10, key: 1 },
    { course: "Math", grade: "B", credit: 20, key: 2 }
  ]);

  const [columnDefs] = useState([
    { field: 'course', editable: true },
    {
      field: 'grade', editable: true, cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [
          "A+", "A", "A-", "B+", "B", "B-", "C+", "C", "C-", "D+", "D", "D-", "F"
        ],
      }
    },
    { field: 'credit', editable: true },
    { field: 'key' },
  ])
const gridRef= useRef()




  const [formula, setFormula] = useState();
  let urlComps = urlUtils.getUrlComponents(useLocation(), units);


  //(1×4.33+2×4.00+1×2.00) / (1+2+1) = 3.58

  useEffect(() => {

    setFormula('y')
  }, [rowData])


  useEffect(() => {

  }, []);




  const addRow = e => {
    //let k = grades.length+1;
    //let currData = rowData;
    //currData.push({ course: "Course", grade: "B", credit: 1, key: 0 });
    //console.log(currData)
    //setRowData(currData);
    gridRef.current.api.applyTransaction({
      add: [{course: "course ", grade: "A", credit: 10}],
      addIndex: 0,
    })
   
    //AgGridReact.gridApi.addRow()  applyTransaction({add: [{}]})
    console.log(rowData)
  }

  const handleClickTo = e => {

  }
  const onCreditChange = (event) => {


    //gradesNew[event.target.key].credit = event.target.value;

    //setGrades({ grades: gradesNew});
  }


  return (
    <>

      <div className='container'>
        <h2>{_title} <i className={_icon}></i> </h2>
        <div className="ag-theme-alpine" style={{ height: 400, width: 600 }}>
          <AgGridReact
          ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            
            >
              
          </AgGridReact>
        </div>

        <h3>{formula}</h3>
        <button onClick={addRow} className="btn btn-primary">Add Course</button>

      </div>

    </>
  );
}

export default GPACalculator;