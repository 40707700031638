

export function getUrlComponents(location, units) {
  
    let locationConvertComponents = location.search.split('-');

    let urlValue = locationConvertComponents[0].substring(1)
    let urlFromUnit
    let urlToUnit
    try {
        urlFromUnit = units.filter(unit => unit.convUnit == locationConvertComponents[1])[0].simbol
        urlToUnit = units.filter(unit => unit.convUnit == locationConvertComponents[3])[0].simbol

    } catch (error) {
    }
    return {urlValue,urlFromUnit,urlToUnit}
}
